import React from 'react'
import { ButtonWrapper as DateButtonWrapper, FormWrapper as DateFormWrapper } from './DateRangePicker.style'
import { ButtonWrapper as DateTimeButtonWrapper, FormWrapper as DateTimeFormWrapper } from './DateTimeRangePicker.style'
import DateComponent from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import DateTimeComponent from '@wojtekmaj/react-datetimerange-picker/dist/entry.nostyle'

// https://github.com/wojtekmaj/react-daterange-picker

type DateRangePickerProps = {
  value: Date[];
  onChange: (range: Date[]) => void;
  showTime?: boolean;
  clearEnabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  style?: "button" | "form";
  autoFocus?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ 
  value, 
  onChange,
  showTime = true,
  clearEnabled = true,
  minDate, maxDate,
  style = "button",
  autoFocus = null,
}) => {

  const handleChange = range => {
    onChange(range);
  }

  const clearIcon = (value && clearEnabled) ? "Clear" : null;

  if (showTime) {
    const StyledComponent = {
      "button": DateTimeButtonWrapper,
      "form": DateTimeFormWrapper,
    }[style];

    return (
      <StyledComponent className="devo-datetimerange-picker">
        <DateTimeComponent
          value={value}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          calendarIcon={null}
          showClock={false}
          maxDetail="minute"
          clearIcon={clearIcon}
          autoFocus={autoFocus}
        />
      </StyledComponent>
    )

  } else {
    const StyledComponent = {
      "button": DateButtonWrapper,
      "form": DateFormWrapper,
    }[style];

    return (
      <StyledComponent className="devo-daterange-picker">
        <DateComponent
          value={value}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          calendarIcon={null}
          clearIcon={clearIcon}
        />
      </StyledComponent>
    )

  }
}

export default DateRangePicker