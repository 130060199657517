import styled from 'styled-components';

const SharedStyling = `
  white-space: nowrap;
  .react-datetimerange-picker__calendar {
    color: var(--primary-label-color);
    background-color: var(--background-color);
    border: var(--devo-border);
    padding: 6px;
    text-align: center;
    border-radius: var(--devo-border-radius);
    z-index: 1000;

    .react-calendar {
      border-radius: var(--devo-border-radius);

      .react-calendar__navigation {
        padding: 4px 0 12px;
      }
  
      button {
        border: none;
        background-color: var(--background-color);
        border-radius: var(--devo-border-radius);
      }

      button:hover:enabled:not(.react-calendar__tile--range):not(.react-calendar__tile--hasActive) {
        background-color: var(--secondary-background-color);
      }

      button:focus {
        outline: none;
      }

      .react-calendar__month-view__weekdays__weekday {
        abbr {
          cursor: default;
          text-decoration: none;
          font-weight: 600;
        }
      }

      .react-calendar__month-view__weekdays__weekday,
      .react-calendar__tile {
        padding: 8px 0;
      }

      .react-calendar__tile--range {
        background-color: var(--gray-6);
        border-radius: 0;
      }

      .react-calendar__tile--range:hover:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
        background-color: var(--gray-5);
      }

      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd,
      .react-calendar__tile--hasActive {
        // border: 2px var(--devo-red) solid;
        background-color: var(--devo-blue);
        color: var(--white);
      }

      .react-calendar__tile--rangeStart:hover,
      .react-calendar__tile--rangeEnd:hover,
      .react-calendar__tile--hasActive:hover {
        background-color: var(--devo-dark-blue);
      }

      .react-calendar__tile--rangeStart {
        border-radius: var(--devo-border-radius) 0 0 var(--devo-border-radius);
      }

      .react-calendar__tile--rangeEnd {
        border-radius: 0 var(--devo-border-radius) var(--devo-border-radius) 0;
      }

      .react-calendar__tile--rangeBothEnds {
        border-radius: var(--devo-border-radius);
      }

      .react-calendar__tile--now {
        font-weight: 800;
      }
    }
  }

  .react-datetimerange-picker__calendar--closed {
    display: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const ButtonWrapper = styled.div`

  ${SharedStyling}

  &, .react-datetimerange-picker__inputGroup {
    display: inline-block;

    .react-datetimerange-picker__inputGroup__input {
      border: none;
      text-align: center;
    }
  
    .react-datetimerange-picker__inputGroup__day,
    .react-datetimerange-picker__inputGroup__month,
    .react-datetimerange-picker__inputGroup__hour,
    .react-datetimerange-picker__inputGroup__minute {
      min-width: 32px;
    }

    .react-datetimerange-picker__inputGroup__year {
      min-width: 52px;
    }

    .react-datetimerange-picker__inputGroup__leadingZero {
      margin-left: 6px;
    }

    .react-datetimerange-picker__inputGroup__input--hasLeadingZero {
      min-width: 14px;
      margin-right: 8px;
    }
  }

  .react-datetimerange-picker__range-divider {
    margin: 0 8px;
  }

  .react-datetimerange-picker__inputGroup, 
  .react-datetimerange-picker__clear-button {
    color: var(--primary-label-color);
    background-color: var(--background-color);
    border: var(--devo-border);
    border-radius: var(--devo-border-radius);
  }

  .react-datetimerange-picker__inputGroup {
    padding: 4px;
  }

  .react-datetimerange-picker__clear-button {
    margin-left: 12px;
    padding: 5px 10px;
  }

  .react-datetimerange-picker__calendar {
    top: 125% !important;
  }
`;

export const FormWrapper = styled.div`

  ${SharedStyling}

  &, .react-datetimerange-picker__inputGroup {
    display: inline-block;

    .react-datetimerange-picker__inputGroup__input {
      border: none;
      text-align: center;
      background-color: var(--secondary-background-color);

      &::placeholder { color: var(--secondary-label-color); }
      :-ms-input-placeholder { color: var(--secondary-label-color); } /* Internet Explorer 10-11 */
      ::-ms-input-placeholder { color: var(--secondary-label-color); } /* Microsoft Edge */
    }
  
    .react-datetimerange-picker__inputGroup__day,
    .react-datetimerange-picker__inputGroup__month {
      min-width: 28px;
    }

    .react-datetimerange-picker__inputGroup__hour,
    .react-datetimerange-picker__inputGroup__minute {
      min-width: 28px;
    }

    .react-datetimerange-picker__inputGroup__leadingZero {
      margin-left: 6px;
    }

    .react-datetimerange-picker__inputGroup__input--hasLeadingZero {
      min-width: 14px;
    }

    .react-datetimerange-picker__inputGroup__year {
      min-width: 44px;
    }
  }

  .react-datetimerange-picker__range-divider {
    margin: 0 8px;
  }

  .react-datetimerange-picker__inputGroup, 
  .react-datetimerange-picker__clear-button {
    color: var(--primary-label-color);
    background-color: var(--secondary-background-color);
    border: none;
    border-radius: var(--devo-border-radius);
  }

  .react-datetimerange-picker__inputGroup {
    padding: 12px;
  }

  .react-datetimerange-picker__clear-button {
    margin-left: 12px;
    padding: 13px 16px;
  }

  .react-datetimerange-picker__clear-button:hover {
    background-color: var(--gray-5);
  }
`;