import { DEFAULT_TIME_FRAME, TimeFrame } from 'helper/datetime/timeFrame'
import React, { useState } from 'react'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'
import TimeFrameDropdown from 'components/Reports/TimeFrameDropdown'
import useDateRange from 'helper/datetime/useDateRange'
import { TimeFrameDescriptionProps, TimeFrameProps } from 'helper/datetime/useTimeFrame'
import { useWindowSizeBreakpoints } from 'helper/useWindowSize'
import { Caption, FlexHCenterDiv, IconButton, Spacer } from 'styles/Global'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'next/router'
import { STORE_REMOTE_REPORT_ROUTE } from 'constants/navigation'

type ToolbarDateReportDropdownProps = {
  buttonAlign?: "left" | "right";
  descriptionProps?: TimeFrameDescriptionProps;
}

const ToolbarDateReportDropdown: React.FC<ToolbarDateReportDropdownProps & TimeFrameProps> = props => {

  const { small: mobileBreakpoint } = useWindowSizeBreakpoints();
  const {
    timeFrame,
    setTimeFrame,
    isCustomTimeFrame,
    getDateRangeDescription,
    fromDate,
    toDate,
  } = props;
  const { buttonAlign = "left", descriptionProps = {} } = props;
  const { dateRange, setDateRange } = useDateRange(props);

  const [customPending, setCustomPending] = useState(false);

  const router = useRouter();

  let flexDirection = buttonAlign == "left" ? "row-reverse" : "row";
  if (mobileBreakpoint) flexDirection = "column-reverse";
  let alignItems = mobileBreakpoint ? "flex-start" : "center";

  const getProductReportQueryParams = () => {
    if (timeFrame && timeFrame !== 'CUSTOM') {
      return `?period=${timeFrame}`;
    }

    if (timeFrame == 'CUSTOM' && fromDate && toDate) {
      return `?from=${
        new Date(fromDate).getTime() / 1000
      }&to=${new Date(toDate).getTime() / 1000}`;
    }

    return '';
  };

  const handleBackButtonClick = () => {
    router.back();
  };

  const handleTimeFrameChange = (timeFrame: TimeFrame) => {
    if (timeFrame === "CUSTOM") {
      setCustomPending(true);
    } else {
      setCustomPending(false);
      setTimeFrame(timeFrame);
    }
  }

  const clearCustomDateRange = () => handleTimeFrameChange(DEFAULT_TIME_FRAME);

  const handleChangeDateRange = (x: Date[]) => {
    if (!x) return clearCustomDateRange();
    setDateRange(x);
    if (customPending) {
      setCustomPending(false);
      setTimeFrame("CUSTOM");
    }
  }

  return (
    <FlexHCenterDiv gap={12} flexDirection={flexDirection} alignItems={alignItems} className={"time-frame-picker"}>
      <Spacer />
      <Caption><b>{getDateRangeDescription(false, descriptionProps)}</b></Caption>
      {(isCustomTimeFrame || customPending) ? (
        <DateRangePicker
          autoFocus
          value={customPending ? null : dateRange}
          onChange={handleChangeDateRange}
          showTime={false}
          maxDate={new Date()}
        />
      ) : (
        <TimeFrameDropdown {...{ timeFrame, setTimeFrame: handleTimeFrameChange }} />
      )}
      {router.route.includes('reports') && router.route !== STORE_REMOTE_REPORT_ROUTE && (
        <IconButton icon={faArrowLeft} onClick={handleBackButtonClick}>
          Back
        </IconButton>
      )}
    </FlexHCenterDiv>
  );
}

export default ToolbarDateReportDropdown