import { DEFAULT_TIME_FRAME, TimeFrame } from 'helper/datetime/timeFrame'
import { TimeFrameProps } from 'helper/datetime/useTimeFrame'
import React, { useState } from 'react'

type UseDateRangeCallbackProps = {
  dateRange: Date[];
  setDateRange: (dateRange: Date[]) => void;
}

const useDateRange = ({
  fromDate, toDate, setDateRange: updateDateRange }: TimeFrameProps,
  callback: (dateRange: Date[]) => void = null,
): UseDateRangeCallbackProps => {

  const [typing, setTyping] = useState(null);

  const dateRange = [fromDate, toDate];

  const setDateRange = (dateRange: Date[]) => {
    clearTimeout(typing);
    setTyping(setTimeout(function () {
      if (!dateRange) return;
      updateDateRange(dateRange);
      if (!!callback) callback(dateRange);
    }, 1000));
  }

  return {
    dateRange,
    setDateRange,
  }
}

export default useDateRange